import React from 'react';
// import TagsInput from 'react-tagsinput';
import { WithContext as ReactTags } from 'react-tag-input';
import { map, includes, filter, cloneDeep } from 'lodash';

import { MdClose as CloseIcon } from 'react-icons/md';

import styles from './talent.module.scss';
import { apiUrls } from '../../constants/apiurls';
import { Spinner } from '../loader/loader';
import tags from '../../redux/modules/tags';
import { useState } from 'react';
import { useEffect } from 'react';
import dataService from '../../services/dataService';
import toast from 'react-hot-toast';
import parseError from '../../services/errorParser';

// const renderTalentTag = ({
//   tag,
//   key,
//   disabled,
//   onRemove,
//   classNameRemove,
//   getTagDisplayValue,
//   ...other
// }) => (
//   <span key={key} {...other}>
//     <span className={styles.talentTagLabel}>{getTagDisplayValue(tag)}</span>
//     {!disabled && (
//       <a className={classNameRemove} onClick={() => onRemove(key)}>
//         <CloseIcon size={16} />
//       </a>
//     )}
//   </span>
// );

export const TalentTags = ({ onAdd, onRemove, userId }) => {
  const [parsedTags, setParsedTags] = useState([]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const {
    data: tagsData,
    isFetching: isTagsLoading,
    error: tagsError,
    isSuccess: tagsSuccess,
  } = tags.list().useTaglistQuery({ extendedPath: apiUrls.getTags() });

  const [createTag, tagsCreateData] = tags.form().useTagcreateMutation();
  const [deleteTag, tagsDeleteData] = tags.delete().useTagdeleteMutation();

  useEffect(() => {
    if (tagsData?.data?.length) {
      let result = dataService.parseTagsForTalent(tagsData?.data, userId);

      setParsedTags(result);
    }
  }, [tagsData?.data]);

  useEffect(() => {
    if (tagsCreateData.isSuccess) {
      if (tagsCreateData?.date?.errors) {
        toast.success(parseError(tagsCreateData?.date?.errors));
        return;
      }

      toast.success('Tag Created Successfully!');
    }
    if (tagsCreateData.error) {
      toast.error('Failed To Create Tags!');
    }
  }, [tagsCreateData]);

  useEffect(() => {
    if (tagsDeleteData.isSuccess) {
      toast.success('Tag Deleted!');
    }
    if (tagsDeleteData.error) {
      toast.error('Failed  To Delete Tag!');
    }
  }, [tagsDeleteData]);

  const handleDelete = (i) => {
    deleteTag({
      body: {
        id: parsedTags[i].id,
      },
    });
  };

  const handleAddition = (tag) => {
    createTag({
      body: {
        name: tag.text,
        users: [
          {
            id: userId,
          },
        ],
      },
    });
  };

  const handleDrag = (tag, currPos, newPos) => {
    let newTags = cloneDeep(parsedTags);

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setParsedTags(newTags);
  };

  // const handleTagClick = (index) => {
  //   console.log('The tag at index ' + index + ' was clicked');
  // };
  return (
    <>
      {isTagsLoading ? (
        <Spinner height={40} width={40} />
      ) : (
        <div className={styles.talentTags}>
          <ReactTags
            tags={parsedTags || []}
            // suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="inline"
            autocomplete
            placeholder="Add Tags"
          />
        </div>
      )}
    </>
  );
};
