import React, { useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { apiUrls } from '@constants/apiurls';
import { routesPath } from 'constants/routesPath';
import { JobHeader } from '../../components/jobHeader/jobHeader';
import jobs from '../../redux/modules/jobs';
import dataService from '../../services/dataService';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchemaService from '../../services/yupSchemaService';
import { JobPostStatus } from '../../components/jobPostStatus/jobPostStatus';
import classNames from 'classnames';
import stylesForms from 'components/formColumns/forms.module.scss';
import styles from 'components/jobForm/jobForm.module.scss';
import stylesJob from '@components/jobIndexCard/JobForm.module.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { handleJobData } from '../../redux/common/commonSlice';
import toast from 'react-hot-toast';

export const JobContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [updateJob, jobUpdateData] = jobs.form().useJobupdateMutation();

  const [createJob, createJobData] = jobs.form().useJobcreateMutation();

  const state = useSelector((state) => state.commonReducer.jobData?.state);
  const jobsData = useSelector((state) => state.commonReducer.jobData);

  const {
    data: jobData,
    error: jobError,
    isLoading: jobLoading,
  } = jobs.list().useJobdetailQuery(
    {
      params: { id: params.jobId },
      extendedPath: apiUrls.getJob(),
    },
    { skip: !params?.jobId, refetchOnFocus: true },
  );

  const [initialValues] = useState(() => {
    return dataService.parseFormDataForJobEdit(jobData?.data?.job) || {};
  });
  const form = useForm({
    resolver: yupResolver(yupSchemaService.jobCreationSchema()),
    mode: 'all',
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  useEffect(() => {
    if (jobUpdateData.isSuccess) {
      toast.success('Job Updated Successfully!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (jobUpdateData.isError) {
      toast.error('Failed To Update Job!');
    }
  }, [jobUpdateData]);

  useEffect(() => {
    if (createJobData?.isSuccess) {
      toast.success('Job Successfully Created!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (createJobData?.error) {
      toast.error('Failed To Post Job!');
    }
  }, [createJobData]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('jobsData');
      localStorage.removeItem('jobFormVlaues');
      localStorage.removeItem('jobFormParsedVlaues');
    };
  }, []);

  const submitForm = (values) => {
    let parsedValues = dataService.parseDataForJobCreationApi(values);

    if (
      new Date(parsedValues?.publish_date).getTime() >=
      new Date(parsedValues?.expiry_date).getTime()
    ) {
      toast.error('Publish time should be less than expirey time');
      return;
    }

    parsedValues.state = state?.state;
    parsedValues.city = state?.city;
    parsedValues.country_id = state?.country_id;
    parsedValues.job_images = state?.images.map((img) => {
      if (img) {
        return { url: img.url || img.image };
      }
    });
    localStorage.setItem(
      'jobsData',
      JSON.stringify({ state: state, job: parsedValues, formValues: values }),
    );
    if (params.jobId) {
      parsedValues.id = parseInt(params.jobId);

      dispatch(handleJobData({ state: state, job: parsedValues, formValues: values }));

      !location.pathname.includes('publish') &&
        localStorage.setItem('jobFormParsedVlaues', JSON.stringify(parsedValues));
      location.pathname.includes('publish')
        ? updateJob(parsedValues)
        : navigate(routesPath.publishOldJob(params?.jobId));
    } else {
      !location.pathname.includes('publish') &&
        localStorage.setItem('jobFormVlaues', JSON.stringify(values));
      dispatch(handleJobData({ state: state, job: parsedValues, formValues: values }));
      location.pathname.includes('publish')
        ? createJob({ body: parsedValues })
        : navigate(routesPath.publishJob());
    }
  };
  return (
    <>
      <JobHeader
        client={jobData?.data?.job}
        job={jobData?.data?.job}
        // projects={projects}
        newEntry={!params?.jobId}
        onCancel={routesPath.dashboard}
        // active="settings"
        // onEdit={(client) => push(routes.editClient(client.id))}
        // onRemove={(client) =>
        //   requireConfirmation([destroy(client.uri), pushAction(routes.dashboard)])
        // }
      />

      <div className={classNames(styles.page, { [stylesJob.applicantsPage]: params?.jobId })}>
        <div
          className={
            (classNames(styles.matchCompCardContent), 'd-flex align-self-center flex-column')
          }
        >
          <div className="container-fluid p-0">
            {jobLoading ? (
              <></>
            ) : (
              <FormProvider {...form}>
                <form
                  onSubmit={form.handleSubmit(submitForm)}
                  className={classNames('form', stylesForms.form)}
                >
                  <div className="row">
                    <div className="col-lg-8">
                      <Outlet context={{ form }} />
                    </div>

                    <div className="col-lg-4">
                      <div className={classNames('ps-0', 'pe-0', 'pe-lg-3 pt-5 mt-4')}>
                        <JobPostStatus
                          showCreate={true}
                          isEditing={params.jobId}
                          loading={jobLoading}
                          form={form}
                          jobData={jobData?.data?.job || jobsData?.job}
                          submitForm={submitForm}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const useJobConatiner = () => {
  return useOutletContext();
};
