import React, { useEffect } from 'react';
import classNames from 'classnames';
import stylesOverlayForm from './overlayForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { TextInput } from 'components/form/textInput';
import { Fieldset } from '../fieldSet/fieldSet';
import { Color } from '../color/color';
import { Acronym } from '../acronym/acronym';
import { Button } from '../button/button';
import projectStyles from './projectForm.module.scss';
import dataService from '../../services/dataService';
import { RoundButton } from '../roundButton/roundButton';
import { MdAdd as AddIcon } from 'react-icons/md';
import { List } from '../list/list';
import pipeline from '../../redux/modules/pipeline';
import { apiUrls } from '../../constants/apiurls';
import { SelectInput } from '../form/selectInput';
import { isEmpty } from 'lodash';

export const ProjectForm = (props) => {
  const {
    className,
    yupSchema,
    colors,
    seniorityLevels,
    isEditing,
    client,
    handleSubmit,
    projects,
    state,
    setState,
    skipHandler,
    projectData,
  } = props;

  const [parsedPipeline, setParsedPipeline] = useState([]);

  const {
    data: pipelinesData,
    isLoading: pipelinesDataLoading,
    error: pipelinesDataError,
    isSuccess: pipelinesDataSuccess,
  } = pipeline.list().usePipelinelistQuery(
    { params: '', extendedPath: apiUrls.getPipelines() },
    {
      // skip: activeTabIndex !== 1,
    },
  );

  const initialValues = projectData ? dataService.parseDataForProject(projectData) : {};

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'all',
    defaultValues: initialValues,
    // shouldUnregister: true,
  });

  useEffect(() => {
    if (pipelinesData?.data) {
      let result = dataService.parsePipelineForSelect(pipelinesData?.data);
      setParsedPipeline(result);
    }
  }, [pipelinesData]);

  return (
    <>
      <FormProvider {...form}>
        <div>
          <form
            className={classNames(
              'form',
              stylesForms.form,
              projectStyles.projectFormContainer,
              className,
              stylesOverlayForm.overlayForm,
            )}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <div>
              <div className="form-section">
                <div className={classNames(stylesForms.formInnerWrapper, 'form-inner-wrapper  ')}>
                  <>
                    {/* <input /> */}
                    <Fieldset>
                      <TextInput
                        placeHolder="e.g. Food and Beverage"
                        label="Project title"
                        autoFocus
                        name="title"
                      />
                      <div
                        style={{
                          fontWeight: '500',
                          color: '#272e41',
                          fontSize: '0.875rem',
                          display: 'block',
                          marginBottom: '0.5rem',
                        }}
                      >
                        Access Level
                      </div>
                      <div className="mt-2 mb-3">
                        <SelectInput
                          name="access_level_id"
                          options={parsedPipeline}
                          placeholder="Select minimum access level"
                        />
                      </div>
                      <Color
                        name="colour_id"
                        colors={colors}
                        label="Avatar Colour"
                        description="Selet a colour of your project"
                      />

                      <Acronym acronym={''} color_id={1} />
                    </Fieldset>
                    <Fieldset>
                      <div
                        style={{
                          fontWeight: '500',
                          color: '#272e41',
                          fontSize: '0.875rem',
                          display: 'block',
                          marginBottom: '0.5rem',
                        }}
                      >
                        Select a project pipeline
                      </div>
                      <div className="mt-2 mb-3">
                        <SelectInput
                          name="pipeline_id"
                          options={parsedPipeline}
                          placeholder="Select a pipeline"
                        />
                      </div>

                      <Button important submit type="sumit">
                        {!isEmpty(projectData) && projectData ? 'Update Project' : 'Add Project '}
                      </Button>

                      <Button
                        cancel
                        onClick={() =>
                          setState((prevState) => ({ ...prevState, addProjects: false }))
                        }
                      >
                        Cancel
                      </Button>
                    </Fieldset>
                  </>
                </div>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  );
};
