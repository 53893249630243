import React, { useCallback, useEffect, useRef, useState } from 'react';
import coverImage from '../../static/images/hotel-cover-default.png';
import { MdEditAttributes } from 'react-icons/md';
import { MdSettings as SettingsIcon } from 'react-icons/md';
import styles from './clientCard.module.scss';
import { Button } from '../button/button';
import { Dropdown } from '../dropDown/dropDown';
import { Heading } from '../heading/heading';
import { MenuItem } from '../menuItem/menuItem';
import { LogoUploader } from '../form/logoUploader';
import { MdOutlineAddPhotoAlternate as UploadCoverIcon } from 'react-icons/md';
import { ImageUpload } from '../imageUpload/imageUpload';
import ImageUploader from '../form/imageUploader';
import { dropZoneimageUploader } from '../../utils/imageUploadHelper';
import clients from '../../redux/modules/clients';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';

export const ClientCard = (props) => {
  const {
    client,
    children,
    isEditable,
    onCoverClick,
    onEdit,
    showSettings,
    standalone,
    onClientRemove,
    extraClass,
    onlogoClick,
    state,
    showUpdateCover,
    key,
    id,
    index,
    setParsedClient,
  } = props;

  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveClient(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id: id, index: index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (clientUpdateData?.isSuccess) {
      toast.success('Client Cover Image updated Successfully!');
    }
    if (clientUpdateData?.isError) {
      toast.error('Failed to update!');
    }
  }, [clientUpdateData]);

  const getCoverImage = (images) => {
    if (state?.images?.length) {
      return state?.images?.find((img) => img.is_cover);
    } else {
      return images.find((img) => img.is_cover);
    }
  };

  const handleCoverChnage = async (e, client) => {
    let tempClient = cloneDeep(client);
    let fileReader = new FileReader();
    if (e.target.files[0]) {
      fileReader.readAsDataURL(e.target.files[0]);
    }
    fileReader.onloadend = async () => {
      try {
        let result = await dropZoneimageUploader(fileReader.result);
        let image = { is_cover: true, type: 1, image: result?.image };
        tempClient.client_images.push(image);

        clientUpdate(tempClient);
      } catch (err) {
        toast.error('Failed to update client!');
      }
    };
  };
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const moveClient = useCallback((dragIndex, hoverIndex) => {
    setParsedClient((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  return (
    <div
      className={`client-profile ${extraClass && styles[extraClass]}  ${
        styles.clientProfileWrapper
      }`}
      ref={ref}
      data-handler-id={handlerId}
      id={id}
      moveCard={moveClient}
    >
      <>
        <div className={`${styles.clientCover} ${standalone && styles.standalone}`}>
          {client?.client_images?.length && client?.client_images?.find((img) => img.is_cover) ? (
            <div
              className={styles.imagePreview}
              style={{
                backgroundImage: `url('  ${
                  getCoverImage(client?.client_images)?.image || client.client_images[0].image
                } ')`,
              }}
              title="Preview"
            />
          ) : !(client?.client_images && client?.client_images[0]) ||
            (client?.client_images?.length &&
              !client?.client_images?.find((img) => img.is_cover)) ? (
            <div
              className={styles.imagePreview}
              style={{
                background: `#131F46`,
              }}
              title="Preview"
            >
              <div className={!showUpdateCover && 'd-none'} style={{ paddingTop: '40px' }}>
                <label htmlFor="cover-upload">
                  <UploadCoverIcon size={25} color={'#364265'} />
                  <div style={{ color: '#364265' }}>Add Cover Photo</div>
                </label>
              </div>

              {/* <ImageUpload backgroundColor={'#364265'} from="dashboard" /> */}

              <input
                onChange={(e) => handleCoverChnage(e, client)}
                type={'file'}
                style={{ display: 'none' }}
                accept="png"
                id="cover-upload"
              />
            </div>
          ) : isEditable ? (
            <Button inline onClick={onCoverClick}>
              <MdEditAttributes size={25} />
              Upload hotel photos
            </Button>
          ) : null}
        </div>
        <div className={styles.clientLogo}>
          {isEditable ? (
            <div className={`${styles.clientLogoUpload} ${styles.haslogo}`}>
              <LogoUploader name="logo" isIcon={true} />
            </div>
          ) : client?.logo ? (
            <div
              onClick={onlogoClick}
              className={styles.clientLogoLink}
              style={{ backgroundImage: `url("${client?.logo}")` }}
            >
              <img
                src={client?.logo[0]?.image || client?.logo}
                width="110"
                height="110"
                alt={client?.title}
                className={styles.clientLogoImage}
              />
            </div>
          ) : (
            !client?.logo && (
              <div
                className={styles.logoPreview}
                style={{
                  backgroundImage: coverImage,
                }}
                title="Preview"
              ></div>
            )
          )}
        </div>
        <div className={styles.clientBody}>
          <div className={styles.clientNameWrapper}>
            {showSettings && (
              <div className={styles.clientSettingsWrapper}>
                <Dropdown zIndex={16} trigger={<SettingsIcon size={20} />}>
                  {onEdit && <MenuItem onClick={() => onEdit(client)}>Client settings</MenuItem>}
                  {onClientRemove && (
                    <MenuItem onClick={() => onClientRemove(client)}>Remove client</MenuItem>
                  )}
                </Dropdown>
              </div>
            )}
            {!isEditable ? (
              <div onClick={onlogoClick} className={styles.clientNameLink}>
                <Heading className={styles.clientName} size="medium">
                  {client?.title}
                </Heading>
              </div>
            ) : (
              <Heading className={styles.clientName} size="medium">
                {client?.title}
              </Heading>
            )}

            <p className={styles.clientLocation} size="small">
              {client?.city && client?.city + ', '}{' '}
              {client?.country?.title && client?.country?.title}
            </p>
          </div>
        </div>
      </>
      {children}
    </div>
  );
};

export default ClientCard;
