import React from 'react';
import { BiFile as FileIcon } from 'react-icons/bi';
import { FiUser as UserIcon } from 'react-icons/fi';
import { MdCreditCard as CardIcon } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const PipelineNav = () => {
  const navigate = useNavigate();
  const activeTabIndex = 1;
  return (
    <>
      <ul>
        <li
          onClick={() => navigate('/settings/pipelines')}
          className={activeTabIndex == 1 ? 'active' : ''}
        >
          <FileIcon size={18} />
          Pipeline templates
        </li>
        <li
        // onClick={() => setActiveIndex(2)}
        // className={activeTabIndex == 2 ? 'active' : ''}
        >
          <UserIcon size={18} />
          Roles and permissions
        </li>
        <li
        // onClick={() => setActiveIndex(3)}
        // className={activeTabIndex == 3 ? 'active' : ''}
        >
          <CardIcon size={18} />
          Billing and account details
        </li>
      </ul>
    </>
  );
};
