import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import yupSchemaService from '../../services/yupSchemaService';
import { Button } from '../button/button';
import { Fieldset } from '../fieldSet/fieldSet';
import { Form } from '../form/form';
import { SelectInput } from '../form/selectInput';
import { TextInput } from '../form/textInput';
import FormTooltip from '../formTooltip/formTooltip';
import { InviteRecruiter } from '../inviteRecruiter/inviteRecruiter';
import { Wizard } from '../wizard/wizard';
import { MdPersonAdd as AddUserIcon } from 'react-icons/md';
import styles from './addRecruiterForm.module.scss';
import { Section } from '@components/section/section';

export const AddRecuriterForm = () => {
  const [initialValues] = useState(() => {
    return {};
  });

  const form = useForm({
    resolver: yupResolver(yupSchemaService.jobCreationSchema()),
    mode: 'all',
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  const onSubmit = (values) => {
    submitForm(values);
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Wizard
        step={1}
        title={'Add a new recruiter to your account'}
        formName="addRecruiterForm"
        icon={<AddUserIcon size={25} />}
      >
        <Section
          title={'ADD NEW RECRUITERS FROM HOSPITALITY LEADERS'}
          headingSize="large"
          contentVisible={true}
        ></Section>
        <FormProvider {...form}>
          <Form initialValues={initialValues} onSubmit={onSubmit}>
            {/* <FormTooltip>
              <strong>Can’t find the right person?</strong>
              <p>
                In order to add a new recruiter you need to be connected with them on Hospitality
                Leaders. First connect with them, then add them as a recruiter.
              </p>
            </FormTooltip> */}

            <Fieldset className={styles.formRecruiterWrapper}>
              <InviteRecruiter avatar={null} />
              <TextInput placeHolder={'Type name or email'} name="name" />
              <SelectInput />
              {/* <Field
            name="talent"
            component={Select}
            placeholder="Type name or email"
            onInputChange={onSearch}
            arrowRenderer={() => <SearchIcon size={20} />}
            isLoading={isSearching}
            options={map(matches, (profile) => ({
              value: profile.talent.url,
              label: fullName(profile),
            }))}
            parse={(option) => option && option.value}
          /> */}

              {/* <Field
            name="level"
            component={SeniorityLevels}
            options={map(seniorityLevels, (l) => ({ value: l.url, label: l.name }))}
            parse={(option) => option && option.value}
            showInfo
          /> */}

              {/* <Button type="submit" important disabled={submitDisabled}> */}
              <div className="py-3">
                <Button type="submit" important disabled={false}>
                  Add and send invite
                </Button>
              </div>
            </Fieldset>
          </Form>
        </FormProvider>
      </Wizard>
    </div>
  );
};
