import dayjs from 'dayjs';
import _, { cloneDeep, isEmpty } from 'lodash';
import { searchAliasis } from '../constants/searchFilters';

const autoCompletionParser = (type, data) => {
  switch (type) {
    case 'country':
      return parseCountries(data);
    case 'hotel':
      return parseHotelType(data);
    case 'language':
      return parseLanguages(data);
    case 'duration':
      // case 'qualifications':
      return parseJobDurations(data);
    case 'clients':
      return parseClients(data);
    case 'users':
      return parseUsers(data);
    case 'jobs':
      return parseJobs(data);

    case 'positionLevels':
      return parsePositionLevels(data);

    case 'departments':
    case 'marital':
    case 'gender':
      return parseDepartments(data);
    case 'membership':
      return parseMembership(data);
    case 'tags':
      return parseUserTags(data);
    case 'qualifications':
      return parseQualifications(data);
    default:
      return data;
  }
};

const parseQualifications = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ id: dat.id, title: dat.title });
  });

  return dataOptions;
};

const parseUserTags = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.name });
  });

  return dataOptions;
};

const parseMembership = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ id: dat.id, label: dat.title, icon: dat.icon });
  });

  return dataOptions;
};

const parseJobDurations = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.title });
  });

  return dataOptions;
};

const parsePositionLevels = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ id: dat.id, title: dat.title, description: dat.description });
  });

  return dataOptions;
};

const parseDepartments = (data) => {
  const dataOptions = [];

  data?.results?.map((dat) => {
    dataOptions.push({ value: dat?.result.id, label: dat?.result.title });
  });

  return dataOptions;
};

const parseCountries = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.title });
  });

  return dataOptions;
};

const parseHotelType = (data) => {
  const dataOptions = [];

  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.title });
  });

  return dataOptions;
};

const parseJobs = (data) => {
  const dataOptions = [];

  data?.jobs.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.title });
  });
  return dataOptions;
};

const parseFormDataForClientEdit = (data) => {
  if (!data) {
    return {};
  }

  let parsedData = { ...data.data };

  return parsedData;
};

const parseFormDataForJobEdit = (data) => {
  if (isEmpty(data)) {
    return {};
  }
  let languagesOptions = [];
  let qualificationsOptions = [];
  let positionLevelsOptions = [];
  let images = [];

  let {
    languages,
    position_levels,
    qualifications,
    job_images,
    colour_id,
    linked_country,
    colour,
    client_id,
    client,
    employment_duration,
    linked_employment_type,
    membership_grade,
    publish_date,
    company,
    city_country,
    auth,
    employment_type,
    slug,
    expiry_date,
    url,
    views,

    ...parsedData
  } = data;
  parsedData.colour_id = parseInt(colour_id);
  parsedData.hidden = 1;
  parsedData.employment_duration_id = 3;
  parsedData.publish_date = publish_date && dayjs(publish_date).format('DD-MMM-YYYY');
  parsedData.expiry_date = expiry_date && dayjs(expiry_date).format('DD-MMM-YYYY');
  parsedData.type = 'Premium';
  parsedData.slug = data?.title;
  parsedData.country_id = linked_country?.official_title;

  parsedData.client_id = client_id;
  if (languages) {
    languages.map((lan) => {
      if (lan) {
        languagesOptions.push(lan.id);
      }
    });
  }
  if (position_levels) {
    position_levels.map((pl) => {
      if (pl) {
        positionLevelsOptions.push(pl?.id);
      }
    });
  }
  if (qualifications) {
    qualifications.map((ql) => {
      if (ql) {
        qualificationsOptions.push(ql.id);
      }
    });
  }
  if (job_images) {
    job_images.map((img) => {
      if (img) {
        images.push(img.url);
      }
    });
  }
  parsedData.position_levels = positionLevelsOptions;
  parsedData.qualifications = qualificationsOptions;
  parsedData.languages = languagesOptions;
  parsedData.job_images = [...images];

  return parsedData;
};

const parseClients = (data) => {
  const dataOptions = [];
  data.map((dat) => {
    dataOptions.push({
      value: dat.id,
      label: dat.title,
      country_id: dat.country_id,
      state: dat.state,
      city: dat.city,
      images: dat.client_images,
    });
  });

  return dataOptions;
};
const parseUsers = (data) => {
  if (!data?.length || isEmpty(data)) {
    return;
  }

  const dataOptions = [];
  data?.map((dat) => {
    dataOptions.push({
      id: dat.id,
      role_id: 7,
      label: dat.full_name,
      name: dat.first_name,
      surname: dat.last_name,
      avatar: dat.avatar,
      value: dat.id,
      email: dat.email,
      pro_title: dat.professional_title,
      contact_number: dat.contact_number,
      skype: dat.skype,
      department: dat.department_id,
      comment: dat.comment,
    });
  });

  return dataOptions;
};

const parseLanguages = (data) => {
  const dataOptions = [];
  data.map((dat) => {
    dataOptions.push({ value: dat.id, label: dat.title });
  });
  return dataOptions;
};

const parseDataForJobCreationApi = (data) => {
  if (isEmpty(data)) {
    return {};
  }
  let languagesOptions = [];
  let qualificationsOptions = [];
  let positionLevelsOptions = [];
  let images = [];

  let { languages, position_levels, qualifications, job_images, colour_id, ...parsedData } = data;
  parsedData.colour_id = parseInt(colour_id);
  parsedData.hidden = 1;
  parsedData.type = 'Premium';
  parsedData.publish_date = dayjs(data.publish_date).format('YYYY-MM-DD');
  parsedData.expiry_date = dayjs(data.expiry_date).format('YYYY-MM-DD');
  parsedData.employment_duration_id = parseInt(data.employment_duration_id);

  if (languages) {
    languages.map((lan) => {
      if (lan) {
        languagesOptions.push({
          id: lan,
        });
      }
    });
  }
  if (position_levels) {
    position_levels.map((pl) => {
      if (pl) {
        positionLevelsOptions.push({
          id: parseInt(pl),
        });
      }
    });
  }
  if (qualifications) {
    qualifications.map((ql) => {
      if (ql) {
        qualificationsOptions.push({ id: parseInt(ql) });
      }
    });
  }
  if (job_images) {
    job_images.map((img) => {
      if (img) {
        images.push({ url: img.image });
      }
    });
  }
  parsedData.position_levels = positionLevelsOptions;
  parsedData.qualifications = qualificationsOptions;
  parsedData.languages = languagesOptions;
  parsedData.job_images = images;
  return parsedData;
};

const jobApplicantsTable = (
  tableData,
  getActions,
  getCoverLetter,
  getFlag,
  getName,
  getAlikeAttributes,
) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    {
      Header: 'Actions',
      accessor: 'actions',
    },
    {
      Header: '',
      accessor: 'coverLetter',
    },
    {
      Header: '',
      accessor: 'flagged',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Job Title',
      accessor: 'jobTitle',
    },
    {
      Header: 'Company',
      accessor: 'company',
    },
    {
      Header: 'Exp',
      accessor: 'experience',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
  ];
  parsedTable.tableData = [];

  tableData.map((applicantData) => {
    parsedTable.tableData.push({
      actions: getActions(applicantData?.result?.user),
      coverLetter: getCoverLetter(
        applicantData?.result?.cover_letter,
        applicantData?.result?.user?.full_name,
        applicantData?.result?.job?.title,
        applicantData?.result?.user?.avatar,
      ),
      flagged:
        applicantData?.result?.user?.notes?.length && getFlag(applicantData?.result?.user?.avatar),
      name: getName(
        applicantData?.result?.user?.full_name,
        applicantData?.result?.user?.avatar,
        applicantData,
      ),
      jobTitle: getAlikeAttributes(applicantData?.result?.user?.current_experience?.title),
      company: getAlikeAttributes(applicantData?.result?.user?.current_experience?.company_name),
      experience: getAlikeAttributes(
        applicantData?.result?.user?.current_experience?.work_duration,
      ),
      city: getAlikeAttributes(applicantData?.result?.user?.current_experience?.area),
    });
  });

  return parsedTable;
};

const parseTagsForTalent = (tags, userId) => {
  let tagsOptions = [];

  if (Array.isArray(tags)) {
    tags?.map((tag) => {
      tag?.users?.map((user) => {
        if (userId == user.id && !Array.isArray(userId)) {
          tagsOptions.push({ id: tag?.id.toString(), text: tag?.name });
        } else if (Array.isArray(userId)) {
          userId.map((usr) => {
            if (usr.original['#'] == user.id) {
              tagsOptions.push({ id: tag?.id.toString(), text: tag?.name });
            }
          });
        }
      });
    });
  }

  return tagsOptions;
};

const parseDataForProject = (data) => {
  let parsedData = {};
  if (!data) {
    return {};
  }
  parsedData = { ...data };
  parsedData.city = data?.city;
  parsedData.address = data?.address_line_1;
  parsedData.latitude = data?.country?.latitude;
  parsedData.longitude = data?.country?.longitude;

  parsedData.teams = null;

  return parsedData;
};

const parsePipeline = (data) => {
  let parsedData = {};
  if (!data) {
    return {};
  }

  parsedData = { ...data };
  let stages = [];
  parsedData?.pipeline_stages?.map((stage, index) => {
    stages.push({
      created: stage?.created,
      deleted: stage?.deleted,
      id: stage?.id,
      modified: stage?.modified,
      pipeline_id: stage?.pipeline_id,
      sort_order: index,
      title: stage.title,
    });
  });
  parsedData.pipeline_stages = stages;
  return parsedData;
};

const usersTable = (
  tableData,
  getActions,
  getName,
  getFlag,
  getAlikeAttributes,
  sortIcon,
  label,
) => {
  let parsedTable = {};

  parsedTable.tableHeader = [
    {
      Header: sortIcon,
      accessor: 'flagged',
    },
    {
      Header: '',
      accessor: 'actions',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'SURNAME',
      accessor: 'surname',
    },
    {
      Header: 'JOB TITLE',
      accessor: 'jobTitle',
    },
    {
      Header: 'COMPANY',
      accessor: 'company',
    },
    {
      Header: 'EXPERIENCE',
      accessor: 'experience',
    },
    {
      Header: 'CITY',
      accessor: 'city',
    },
    {
      Header: 'COUNTRY',
      accessor: 'country',
    },
    {
      Header: 'INSTITUTION',
      accessor: 'institution',
    },
    {
      Header: 'QUALIFICATIONS',
      accessor: 'qualifications',
    },
  ];
  parsedTable.tableData = [];

  if (tableData?.length) {
    tableData?.map((applicantData) => {
      if (label && applicantData?.result?.country?.title === label) {
        parsedTable.tableData.push({
          '#': applicantData?.result?.id,
          actions: getActions(applicantData?.result),
          flagged: getFlag(applicantData?.result?.avatar),
          name: getName(
            applicantData?.result?.first_name,
            applicantData?.result?.avatar,
            applicantData?.result,
          ),
          surname: getAlikeAttributes(applicantData?.result?.last_name),
          jobTitle: getAlikeAttributes(applicantData?.result?.current_experience?.title || '-'),
          company: getAlikeAttributes(
            applicantData?.result?.current_experience?.company_name || '-',
          ),
          // company: getAlikeAttributes(applicantData?.result?.current_experience?.name || '-'),
          experience: getAlikeAttributes(
            applicantData?.result?.current_experience?.work_duration || '-',
          ),
          city: getAlikeAttributes(applicantData?.result?.current_experience?.area || '-'),
          country: getAlikeAttributes(applicantData?.result?.country?.title || '-'),
          institution: getAlikeAttributes(
            applicantData?.result?.educations.length > 0
              ? applicantData?.result?.educations[0]?.institution
              : '-',
          ),
          qualifications: getAlikeAttributes(
            applicantData?.result?.educations.length > 0
              ? (applicantData?.result?.educations[0]?.qualification?.title == 'Other'
                  ? applicantData?.result?.educations[0]?.qualification_type_title
                  : applicantData?.result?.educations[0]?.qualification.title) || '-'
              : '-',
          ),
        });
      } else if (!label) {
        parsedTable.tableData.push({
          '#': applicantData?.result?.id,
          actions: getActions(applicantData?.result),
          flagged: getFlag(applicantData?.result?.avatar),
          name: getName(
            applicantData?.result?.first_name,
            applicantData?.result?.avatar,
            applicantData?.result,
          ),
          surname: getAlikeAttributes(applicantData?.result?.last_name),
          jobTitle: getAlikeAttributes(applicantData?.result?.current_experience?.title || '-'),
          company: getAlikeAttributes(
            applicantData?.result?.current_experience?.company_name || '-',
          ),
          // company: getAlikeAttributes(applicantData?.result?.current_experience?.name || '-'),
          experience: getAlikeAttributes(
            applicantData?.result?.current_experience?.work_duration || '-',
          ),
          city: getAlikeAttributes(applicantData?.result?.current_experience?.area || '-'),
          country: getAlikeAttributes(applicantData?.result?.country?.title || '-'),
          institution: getAlikeAttributes(
            applicantData?.result?.educations.length > 0
              ? applicantData?.result?.educations[0]?.institution
              : '-',
          ),
          qualifications: getAlikeAttributes(
            applicantData?.result?.educations.length > 0
              ? (applicantData?.result?.educations[0]?.qualification?.title == 'Other'
                  ? applicantData?.result?.educations[0]?.qualification_type_title
                  : applicantData?.result?.educations[0]?.qualification.title) || '-'
              : '-',
          ),
        });
      }
    });
  }

  return parsedTable;
};

const candidatesTable = (
  projectData,
  getAlikeAttributes,
  getApplied,
  getAttachment,
  getEmail,
  getName,
  bagIcon,
  emailIcon,
  attachmentIcon,
) => {
  let parsedCandidatesTable = {};

  parsedCandidatesTable.tableHeader = [
    {
      Header: bagIcon(),
      accessor: 'applied',
    },
    {
      Header: emailIcon(),
      accessor: 'emailIcon',
    },
    {
      Header: attachmentIcon(),
      accessor: 'attachment',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'SURNAME',
      accessor: 'surname',
    },
    {
      Header: 'JOB TITLE',
      accessor: 'jobTitle',
    },
    {
      Header: 'COMPANY',
      accessor: 'company',
    },
    {
      Header: 'EXPERIENCE',
      accessor: 'experience',
    },
    {
      Header: 'CITY',
      accessor: 'city',
    },
    {
      Header: 'COUNTRY',
      accessor: 'country',
    },
    {
      Header: 'INSTITUTION',
      accessor: 'institution',
    },
    {
      Header: 'QUALIFICATIONS',
      accessor: 'qualifications',
    },
  ];

  parsedCandidatesTable.tableData = [];
  let tempUsers = cloneDeep(projectData?.projects_pipeline_stages_users);
  projectData?.pipeline?.pipeline_stages?.map((stage) => {
    parsedCandidatesTable.tableData.push({
      name: stage?.title,
      expanded: true,

      subRows: tempUsers
        ?.map((user, i) => {
          if (user.pipeline_stage_id === stage.id) {
            return {
              applied: getApplied(true),
              attachment: getAttachment(true),
              name: getName(user?.user?.first_name, user?.user?.avatar_cdn, user),
              emailIcon: getEmail(true),
              surname: user.user.last_name,
              jobTitle: user?.user?.experiences[0]?.title,
              company: user?.user?.company?.name,
              experience:
                (user?.user?.experiences?.length && user?.user?.experiences?.length + ' year') ||
                '',
              country: user?.user?.experiences[0]?.country?.title,
              institution: user?.user?.educations[0]?.institution,
              qualifications: user?.user?.educations[0]?.qualification_type_title,
            };
          }
        })
        .filter(Boolean),
    });
  });

  return parsedCandidatesTable;
};

const parsedKanbanColumns = (data, selectedCards, getActionBar, getCheckBoxAction) => {
  let parsedData = {};
  parsedData.id = data?.id;
  parsedData.columns = [];

  let tempCards = cloneDeep(data?.projects_pipeline_stages_users);
  debugger;
  data?.pipeline?.pipeline_stages.map((data, index) => {
    parsedData.columns.push({
      id: data.id,
      title: data.title,
      pipelineId: data.pipeline_id,
      actionBar: getActionBar({ fromPosition: index, fromColumnId: data?.id }),
      selectedCards: selectedCards.filter((cd) => cd.pipeline_stage_id === data.id),

      cards: tempCards
        ?.map((usr) => {
          if (usr.pipeline_stage_id === data.id) {
            usr.checkBoxAction = getCheckBoxAction(usr);
            usr.name = usr.id;
            return usr;
          }
        })
        .filter(Boolean),
    });
  });

  parsedData.columns.map((col) => (col['candidateCount'] = col.cards?.length));
  debugger;
  return parsedData;
};

const parsedKanbanColumnsBin = (data, selectedCards, getActionBar, getCheckBoxAction) => {
  let parsedData = {};
  parsedData.id = data?.id;
  parsedData.columns = [];

  let tempCards = cloneDeep(
    data?.projects_pipeline_stages_users?.filter((user) => user.binned === 1),
  );
  data?.pipeline?.pipeline_stages.map((data, index) => {
    parsedData.columns.push({
      id: data.id,
      title: data.title,
      pipelineId: data.pipeline_id,
      actionBar: getActionBar({ fromPosition: index, fromColumnId: data.id }),
      selectedCards: selectedCards.filter((cd) => cd.pipeline_stage_id === data?.id),

      cards: tempCards
        ?.map((usr) => {
          if (usr.pipeline_stage_id === data.id) {
            usr.checkBoxAction = getCheckBoxAction(usr);
            usr.name = usr.id;
            return usr;
          }
        })
        .filter(Boolean),
    });
  });

  parsedData.columns.map((col) => (col['candidateCount'] = col.cards?.length));
  return parsedData;
};

const parseSavedSearchUrl = (url, data) => {
  if (!url) {
    return {};
  }

  let parsedObject = {
    user_tags: [],
    qualification_achieved: [],
    job_titles: [],
    studied_at_institution: '',
    current_position: '',
    current_department: '',
    gender: '',
    from_company: '',
    rank_experience: [],
    membership_grades: null,
    fluently_speaks: [],
    marital_status: '',
    countries: [],
    employment_type_id: null,

    position_levels: [],
  };

  let tempUrl = url.replace('/rest/users/search?', '');
  tempUrl = tempUrl.replaceAll(`%5B`, '');
  tempUrl = tempUrl.replaceAll(`[`, '');
  tempUrl = tempUrl.replaceAll(`]`, '');
  tempUrl = tempUrl.replaceAll('%5D', '');
  tempUrl = tempUrl.replaceAll('+', ' ');
  tempUrl = tempUrl.replaceAll('filters', '');
  let tempUrlArrays = tempUrl.split('&');

  tempUrlArrays.map((field) => {
    if (searchAliasis[field.split('=')[0].replace(/[0-9]/g, '')]) {
      if (searchAliasis[field.split('=')[0].replace(/[0-9]/g, '')].type === 'array') {
        parsedObject[field.split('=')[0]?.replace(/[0-9]/g, '')].push(
          parseInt(field.split('=')[1]),
        );
      } else {
        parsedObject[field.split('=')[0].replace(/[0-9]/g, '')] = field.split('=')[1];
      }
    }
  });

  return parsedObject;
};

const parseTagsForTable = (data) => {
  if (!data) {
    return [];
  }
  let tempData = [];

  data.map((dat) => {
    tempData.push({
      id: dat.name,
      tagId: dat.id,
      text: dat.name,
    });
  });
  return tempData;
};

const parseTags = (data) => {
  if (!data) {
    return {};
  }
  return { id: data?.data?.name, tagId: data?.data?.id, text: data?.data?.name };
};

const parsePipelineForSelect = (data) => {
  if (!data) {
    return [];
  }
  let dataOptions = [];

  data.map((dat) => dataOptions.push({ value: dat.id, label: dat.title }));
  return dataOptions;
};

const parseRecruitersListing = (data) => {
  if (!data) {
    return [];
  }
  let dataOptions = [];

  data.map((dat) => {
    dataOptions = [...dataOptions, ...dat?.recruiters];
  });

  return dataOptions;
};

const parseTeamsListing = (data) => {
  if (!data) {
    return [];
  }
  let dataOptions = [];

  data.map((dat) => {
    dataOptions.push(dat);
  });

  return dataOptions;
};

export default {
  parseHotelType,
  parseCountries,
  autoCompletionParser,
  parseFormDataForClientEdit,
  parseFormDataForJobEdit,
  parseDataForJobCreationApi,
  jobApplicantsTable,
  parseTagsForTalent,
  parseDataForProject,
  parsePipeline,
  usersTable,
  parseSavedSearchUrl,
  parseTagsForTable,
  parseTags,
  parsePipelineForSelect,
  parsedKanbanColumns,
  candidatesTable,
  parseRecruitersListing,
  parseTeamsListing,

  parsedKanbanColumnsBin,
};
