import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { isEmpty, map } from 'lodash';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ClientCard from '@components/clientCard/clientCard';
import { Spinner } from '@components/loader/loader';
import { ProjectForm } from '@components/projectForm/projectForm';
import { Section } from '@components/section/section';
import { Wizard } from '@components/wizard/wizard';
import { apiUrls } from '@constants/apiurls';
import clients from '@redux/modules/clients';
import projects from '@redux/modules/projects';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import yupSchemaService from '@services/yupSchemaService';
import { List } from '@components/list/list';
import { BaseCard } from '@components/baseCard/baseCard';
import { MdWork as WorkIcon } from 'react-icons/md';
import { avatarOptions } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { updateWizardStep } from '@redux/common/commonSlice';
import { RoundButton } from 'components/roundButton/roundButton';
import { MdAdd as AddIcon } from 'react-icons/md';
import { Avatar } from '@components/avatar/avatar';
import classNames from 'classnames';
import styles from '@components/contactForm/contactForm.module.scss';
import { Heading } from '@components/heading/heading';
import { views } from '../../constants/views';
import { OverlayContainer } from '../overlayContainer/OverlayContainer';
import { handleViewsData } from '../../redux/common/commonSlice';
import { routesPath } from '../../constants/routesPath';

export const ProjectFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const [state, setState] = useState({
    colors: [],
    positionLevels: [],
    loading: false,
    addProjects: false,
  });

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
    refetch: clientsRefetch,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientId || state.addProjects,
    },
  );

  const {
    data: projectData,
    error: projectError,
    isFetching: projectLoading,
    refetch: projectRefetch,
  } = projects.list().useProjectdetailQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProject() },
    {
      skip: !params.projectId,
    },
  );

  const [createProject, projectCreateData] = projects.form().useProjectcreateMutation();

  const [updateProject, updateProjectData] = projects.form().useProjectupdateMutation();

  const [deleteProject, deleteProjectData] = projects.delete().useProjectdeleteMutation();

  useEffect(() => {
    if (params.projectId) {
      setState((prevState) => ({ ...prevState, addProjects: true }));
    }

    dispatch(
      updateWizardStep({
        step: 2,
      }),
    );
    getInitailData();
  }, []);

  useEffect(() => {
    if (updateProjectData.isSuccess) {
      toast.success('Project Updated!');
      setState((prevState) => ({ ...prevState, addProjects: false }));
    }
    if (updateProjectData.error) {
      toast.error('Failed To Update Project!');
    }
  }, [updateProjectData]);
  useEffect(() => {
    if (projectCreateData?.isSuccess) {
      toast.success('Project Created Successfully!');
      setState((prevState) => ({ ...prevState, addProjects: false }));
      // navigate(routesPath.dashboard, { replace: false });
    }
    if (projectCreateData?.isError) {
      toast.error('Failed To Create Project!');
    }
    if (deleteProjectData?.isSuccess) {
      toast.success('Project Deleted Successfully!');
      dispatch(handleViewsData(null));
      clientsRefetch({ params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() });
    }
  }, [projectCreateData, deleteProjectData]);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { type: 'language', url: apiUrls.getPositionLevels() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        positionLevels: response[1],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleSubmit = (values) => {
    values.company_role_id = 1;

    values.client_id = clientData?.data?.id;

    if (typeof state.addProjects === 'object' && state.addProjects) {
      values.city = clientData?.data?.city;
      values.address = clientData?.data?.address_line_1;
      values.latitude = clientData?.data?.country?.latitude;
      values.longitude = clientData?.data?.country?.longitude;
      values.country_id = clientData?.data?.country_id;
      values.teams = null;
      // values.id = parseInt(params.projectId);
      updateProject(values);
    } else {
      createProject({ body: values });
    }
  };

  const findColor = (colorId) => {
    let color = state.colors?.find((col) => col.id === colorId);
    return color?.hex;
  };

  return (
    <>
      <Wizard
        step={3}
        title="Nearly there."
        nextTitle="You can now add a project."
        formName="project"
        icon={<WorkIcon size={25} />}
        action={
          state?.addProjects ? (
            <>
              <Button
                inline
                regular
                //  onClick={() => finalize() && push(routes.dashboard)}
                onClick={() => navigate(routesPath.dashboard, { replace: false })}
              >
                I’ll add projects later
              </Button>
              <p style={{ color: '#686868', marginTop: '20px' }}>
                You can add projects at any stage
              </p>
              <p style={{ marginTop: '-20px', color: '#686868' }}> from the projects menu</p>
            </>
          ) : (
            <>
              <Button
                inline
                submit
                onClick={() => navigate(routesPath.dashboard, { replace: false })}
              >
                Done
              </Button>
              <p style={{ color: '#686868', marginTop: '20px' }}>
                You can add projects at any stage
              </p>
              <p style={{ marginTop: '-20px', color: '#686868' }}> from the projects menu</p>
            </>
          )
        }
      >
        <div>
          <ClientCard isEditable={false} client={clientData?.data}>
            <Section
              title={params?.projectId ? 'Edit Project' : 'Add Project'}
              headingSize="large"
              contentVisible={true}
            >
              {clientLoading ? (
                <Spinner height={100} width={100} />
              ) : (
                <div className="form-section">
                  {state?.addProjects && (
                    <ProjectForm
                      yupSchema={yupSchemaService.addProject()}
                      colors={state.colors}
                      seniorityLevels={state.positionLevels}
                      client={clientData?.data}
                      createProject={createProject}
                      projectCreateData={projectCreateData?.data}
                      loading={projectCreateData?.isLoading || clientLoading}
                      handleSubmit={handleSubmit}
                      state={state}
                      setState={setState}
                      isEdit={params.projectId}
                      projectData={state?.addProjects}
                    />
                  )}
                  {/* {!isEmpty(clientData?.data?.projects) && !state?.addProjects && (
                    <List>
                      {map(clientData?.data?.projects, (project) => (
                        <>
                          <BaseCard
                            colors={state.colors}
                            onRemove={(item) =>
                              dispatch(
                                handleViewsData({
                                  project: project,
                                  currentView: views.confirmationDialog,
                                }),
                              )
                            }
                            onEdit={() =>
                              setState((prevState) => ({ ...prevState, addProjects: project }))
                            }
                            onClick={() =>
                              navigate(
                                routesPath.projectPipeline(project.id, project?.pipeline_id),
                                { replace: false },
                              )
                            }
                            key={`project-${project.id}`}
                            item={project}
                            title={project.title}
                            size="medium"
                            avatar={avatarOptions(project, 'project')}
                            subhead={project?.title}
                            color={findColor(project.colour_id)}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      ))}
                    </List>
                  )} */}
                  {!state.addProjects && (
                    <div className="d-flex justify-content-center py-3">
                      <RoundButton
                        onClick={() =>
                          setState((prevState) => ({ ...prevState, addProjects: true }))
                        }
                      >
                        <AddIcon size={30} />
                      </RoundButton>
                    </div>
                  )}
                </div>
              )}
            </Section>
            <Section title="CLIENT CONTACTS" headingSize="large" contentVisible={true}>
              {!isEmpty(clientData?.data?.client_contacts) && (
                <List>
                  {map(clientData?.data?.client_contacts, (contact) => (
                    <div className="d-flex gap-2 align-items-center">
                      <Avatar
                        src={contact?.user?.avatar}
                        className={classNames(styles.avatar, styles.img_avatar)}
                        size={25}
                      />
                      <Heading size="small" className={styles.contactName}>
                        {contact?.first_name + ' ' + contact?.last_name}
                      </Heading>
                    </div>
                  ))}
                </List>
              )}
            </Section>
          </ClientCard>
        </div>
        {currentUIState?.currentView === views.confirmationDialog && (
          <OverlayContainer
            closeOverlay={() => {
              dispatch(handleViewsData(null));
            }}
            currentView={views.confirmationDialog}
            acceptConfirmation={() => {
              deleteProject({ body: { id: currentUIState.project.id } });
            }}
            declineConfirmation={() => dispatch(handleViewsData(null))}
          />
        )}
      </Wizard>
    </>
  );
};
