import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useTable, useSortBy, useRowSelect, useExpanded, TableInstance } from 'react-table';
import styles from './table.module.scss';
import { isEmpty } from 'lodash';
import checkBoxStyles from 'components/formColumns/forms.module.scss';
import { ActionsBar } from 'components/actionsBar/actionsBar';
import { KanbanActionsBar } from '../kanbanActionBar/kanbanActionBar';
import { KanbanTableAction } from '../kanbanTableAction/kanbanTableAction';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();

  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <label className={classnames('form-element')}>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </label>
    </>
  );
});

function Table({
  columns,
  data,
  tableStriped,
  tableClass,
  className,
  pipelineData,
  isActionbar,
  isSeacrh,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,

    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useExpanded,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              {/* {isActionbar ||
                (isSeacrh &&  */}
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              {/* // )
                // } */}
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              {isActionbar ||
                (isSeacrh && <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />)}
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // useEffect(() => {
  //   console.log(selectedFlatRows);
  // }, [selectedFlatRows]);

  const filteredRows = () => {
    let filterRows = null;

    if (!isEmpty(selectedRowIds) && isFiltered) {
      filterRows = rows.filter((row) => selectedRowIds[row.id]);
      // setSelectedRows(filterRows);
      return filterRows;
    } else {
      return rows;
    }
  };

  return (
    <>
      <div className={classnames('table-responsive', className)}>
        <div>
          {isActionbar && !isSeacrh ? (
            <KanbanTableAction
              tableColumns={allColumns}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              data={selectedFlatRows}
              pipelineData={pipelineData}
            />
          ) : (
            <ActionsBar
              tableColumns={allColumns}
              setIsFiltered={setIsFiltered}
              isFiltered={isFiltered}
              data={selectedFlatRows}
              // selectedRows={selectedRows}
              onShowSelected={null}
              visible
            />
          )}
        </div>
        <table
          minRows={0}
          className={classnames(
            'table',
            styles.table,
            { [styles.tableStriped]: tableStriped },
            styles[tableClass],
          )}
          {...getTableProps()}
        >
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers &&
                  headerGroup.headers?.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {filteredRows()?.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className="position-relative"
                  {...row.getRowProps()}
                  onClick={() => row.toggleRowExpanded(!row.isExpanded)}
                >
                  {row?.cells &&
                    row.cells.map((cell, i) => {
                      if (
                        cell.column.Header == 'Name' &&
                        // cell.row.canExpand === true &&
                        cell.row?.original?.subRows
                      ) {
                        return (
                          <td className={styles.expansionRow} {...cell.getCellProps()}>
                            {' '}
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return <td {...cell.getCellProps()}> {cell.render('Cell')}</td>;
                      }
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <br />
      <div>Showing {rows.length} rows</div>
    </>
  );
}

export const TableWrapper = (props) => {
  const {
    data,
    columns,
    tableStriped,
    tableClass,
    className,
    pipelineData,
    isActionbar,
    isSeacrh,
  } = props;

  return (
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <Table
        className={className}
        tableClass={tableClass}
        columns={columns}
        data={data}
        tableStriped={tableStriped}
        pipelineData={pipelineData}
        minRows={0}
        isActionbar={isActionbar}
        isSeacrh={isSeacrh}
      />
    </div>
  );
};
