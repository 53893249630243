import React, { useEffect } from 'react';
import styles from './jobConfirmForm.module.scss';
import stylesForms from 'components/formColumns/forms.module.scss';
import { JobLabel } from '../jobLabel/jobLabel';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { useState } from 'react';
import { fetchAutoCompleteData } from '../../services/autoCompleteService';
import { apiUrls } from '../../constants/apiurls';
import { isEmpty } from 'lodash';

export const JobConfirmForm = (props) => {
  const { client, clientLogo, employmentTypeName } = props;
  const jobsData = useSelector((state) => state.commonReducer.jobData);
  let jobData = structuredClone(jobsData);

  if (isEmpty(jobData?.job)) {
    jobData.job = JSON.parse(localStorage.getItem('jobsData'))?.job;
  }

  const [state, setState] = useState({
    colors: [],
    employmentTypes: [],
    positionLevels: [],
    preferredQualifications: [],
    languages: [],
    membershipGrades: [],
    images: [],
    durations: [],
    clientData: null,
    city: '',
    state: '',
    country_id: '',

    loading: false,
  });

  useEffect(() => {
    getInitailData();
  }, []);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { url: apiUrls.getEmploymentTypes() },
      { url: apiUrls.getPositionLevels() },
      { url: apiUrls.getQualifications() },
      { type: 'language', url: apiUrls.getLanguages() },
      { url: apiUrls.getMemberShipGrades() },
      { type: 'duration', url: apiUrls.getJobDuration() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        employmentTypes: response[1],
        positionLevels: response[2],
        preferredQualifications: response[3],
        languages: response[4],
        membershipGrades: response[5],
        durations: response[6],
      }));
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  return (
    <form className={classNames('form')}>
      <div className={classNames(styles.col_2_3, styles.doubleRightPadding)}>
        {state.loading ? (
          <div className="d-flex justify-content-center">Loading</div>
        ) : (
          <div className={classNames(styles.compCardContainer)}>
            <div className={classNames(styles.compCardHeaderContainer)}>
              <JobLabel job={jobData?.job} client={client} clientLogo={clientLogo} />
            </div>
            {/* JOB DETAILS */}
            <div className={classNames(styles.compCardTitleContainer)}>
              <span className={classNames(styles.dividerTextLarge)}>JOB DETAILS</span>
            </div>
            {/* EMPOYMENT TYPE */}
            <div className={classNames(styles.compCardContent, 'd-block')}>
              <span className={classNames(styles.inputTitle)}>Employment type</span>
              <div className={classNames(styles.textSmall)}>
                <p>
                  {' '}
                  {state?.employmentTypes?.find(
                    (empTypes) => empTypes.id === jobData?.job?.employment_type_id,
                  )?.title || ''}
                </p>
              </div>
            </div>
            {/* POSITION LEVEL */}
            <div className={classNames(styles.compCardContent, 'd-block')}>
              <span className={classNames(styles.inputTitle)}>Position level</span>
              <div className={classNames(styles.textSmallPadding)}>
                <div>
                  {jobData?.job?.position_levels?.length &&
                    map(state.positionLevels, (positionLevel, key) => (
                      <div key={key}>
                        {jobData.job?.position_levels?.find(
                          (pLevel) => pLevel?.id == positionLevel?.id,
                        )
                          ? positionLevel?.title
                          : ''}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={classNames(styles.compCardContent, 'd-block')}>
              {jobData?.job?.description && (
                <>
                  <span className={classNames(styles.inputTitle)}>Job description</span>
                  <div
                    className={classNames(styles.textSmall)}
                    dangerouslySetInnerHTML={{
                      __html: `<p>${jobData?.job && jobData?.job?.description}</p>`,
                    }}
                  />
                </>
              )}
            </div>

            {/* CANDIDATE DETAILS */}

            <div className={classNames(styles.compCardTitleContainer)}>
              <span className={classNames(styles.dividerTextLarge)}>CANDIDATE DETAILS</span>
            </div>
            {/* PREFERRED QUALIFICATION */}

            {jobData?.job?.qualifications?.length && (
              <div className={classNames(styles.compCardContent, 'd-block')}>
                <span className={classNames(styles.inputTitle)}>Preferred Qualification</span>
                <div className={classNames(styles.textSmallPadding)}>
                  <div>
                    {map(state?.preferredQualifications, (preferredQualification, key) => (
                      <div key={key}>
                        {jobData.job?.qualifications?.find(
                          (qualification) => qualification?.id === preferredQualification?.id,
                        )
                          ? preferredQualification?.title
                          : ''}
                      </div>
                      //   </If>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className={classNames(styles.compCardContent, 'd-block')}>
              {jobData?.job?.languages?.length && (
                <>
                  <span className={classNames(styles.inputTitle)}>Language</span>
                  <div className={classNames(styles.textSmall)}>
                    <p>
                      {map(state?.languages, (languageOption, key) => (
                        // <If condition={job.language.indexOf(languageOption.url) > -1}>
                        <span key={key}>
                          {jobData.job?.languages?.find(
                            (lang) => lang?.id === languageOption?.value,
                          )
                            ? languageOption?.label
                            : ''}
                        </span>
                        // </If>
                      ))}
                    </p>
                  </div>
                </>
              )}
            </div>
            {/* MEMBERSHIP GRADES */}

            {/* <div className={classNames(styles.compCardContent)}>
        <If condition={!isEmpty(membershipGrades) && !isEmpty(job && job.membership_grade)}>
          <span className={classNames(styles.inputTitle)}>Membership Grade</span>
          <div className={classNames(styles.textSmallPadding)}>
            <div>
              <If
                condition={!isEmpty(membershipGrades) && !isEmpty(job && job.membership_grade)}
              >
                {map(membershipGrades, (membershipGrade, key) => (
                  <If condition={job.membership_grade.indexOf(membershipGrade.url) > -1}>
                    <div key={key} className={classNames(styles.membershipGradeItem)}>
                      <span
                        className={classNames(styles.membershipGradeBadge)}
                        style={{
                          backgroundImage: `url('images/badge_${membershipGrade.key}.png')`,
                        }}
                      />
                      <span>{membershipGrade.value}</span>
                    </div>
                  </If>
                ))}
              </If>
            </div>
          </div>
        </If>
      </div> */}
            {/* COMPANY DETAILS */}

            <div className={classNames(styles.compCardTitleContainer)}>
              <span className={classNames(styles.dividerTextLarge)}>COMPANY DETAILS</span>
            </div>
            <div className={classNames(styles.compCardContent, 'd-block')}>
              {jobData?.job?.company_name && (
                <>
                  <span className={classNames(styles.inputTitle)}>Company name</span>
                  <div className={classNames(styles.textSmall)}>
                    <p>{job && job.company_name}</p>
                  </div>
                </>
              )}
              <div>
                {jobData?.job?.company_description && (
                  <>
                    <span className={classNames(styles.inputTitle, 'd-flex')}>
                      Company description
                    </span>
                    <div
                      className={classNames(styles.textSmall)}
                      dangerouslySetInnerHTML={{
                        __html: `<p>${jobData?.job?.company_description}</p>`,
                      }}
                    />
                  </>
                )}
              </div>
              {jobData?.job?.city && (
                <>
                  <span className={classNames(styles.inputTitle)}>City</span>
                  <div className={classNames(styles.textSmall)}>
                    <p>{jobData?.job?.company_city}</p>
                  </div>
                </>
              )}
              {/* 
              {jobData?.job?.state && (
                <>
                  <span className={classNames(styles.inputTitle)}>Country</span>
                  <div className={classNames(styles.textSmall)}>
                    <p>{jobData?.job?.state}</p>
                  </div>
                </>
              )} */}
              {/*       <If condition={job.company_state_province_or_region}>
          <span className={classNames(styles.inputTitle)}>State / region / province</span>
          <div className={classNames(styles.textSmall)}>
            <p>{job && job.company_state_province_or_region}</p>
          </div>
        </If>*/}

              {jobData?.job?.company_website && (
                <>
                  <span className={classNames(styles.inputTitle)}>Website</span>
                  <div className={classNames(styles.textSmall)}>
                    <p>{jobData?.job?.company_website}</p>
                  </div>
                </>
              )}
            </div>

            {/* JOB POST BRANDING */}

            {(jobData?.job?.job_images?.length || jobData?.job?.logo) && (
              <>
                <div className={classNames(styles.compCardTitleContainer)}>
                  <span className={classNames(styles.dividerTextLarge)}>JOB POST BRANDING</span>
                </div>
                <div className={classNames(styles.compCardContent, 'd-block')}>
                  {jobData?.job?.logo && (
                    <>
                      <span className={classNames(styles.inputTitle)}>Company logo</span>
                      <div className={classNames(styles.companyLogoWrapper)}>
                        <div className={classNames(styles.companyLogo)}>
                          <img
                            src={jobData.job.logo}
                            width="110"
                            height="110"
                            alt={jobData?.job?.title}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {jobData?.job?.job_images?.length && (
                    <>
                      <span className={classNames(styles.inputTitle)}>Company photos</span>
                      <div className={classNames(styles.companyPhotos)}>
                        {jobData?.job?.job_images?.find((img) => img.isCover) && (
                          <div className={classNames(styles.jobCoverImage)}>
                            <img
                              alt=""
                              src={jobData?.job?.job_images?.find((img) => img.isCover)?.url}
                            />
                          </div>
                        )}

                        {jobData?.job?.job_images?.length &&
                          map(jobData?.job?.job_images, (image, key) => (
                            <div key={key} className={classNames(styles.jobImage)}>
                              <img alt="" src={image.url} />
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* <div className={classNames(styles.col_1_3)}>
        <JobPostStatus
          job={job}
          showConfirm
          handleSubmit={handleSubmit}
          onSubmit={submitForm}
          isSubmitting={isSubmitting}
          refreshJobData={refreshJobData}
        />
      </div> */}
    </form>
  );
};
