import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routesPath } from '@constants/routesPath';
import { JobsContainer } from '@containers/jobsContainer/jobsContainer';
import { ClientCreationContainer } from '@containers/clientCreationContainer/ClientCreationContainer';
import { LoginContainer } from '@containers/loginContainer/LoginContainer';
import { Toaster } from 'react-hot-toast';
import { DashboardContainer } from '@containers/dashboardContainer/DashBoardContainer';
import { PrivateRoute } from 'routes/privateRoute';
import { JobsFormContainer } from '@containers/jobsFormContainer/JobsFormContainer';
import { JobApplicantsContainer } from '@containers/jobApplicantsContainer/jobApplicantsContainer';
import { ProjectFormContainer } from '@containers/projectFormContainer/ProjectFormContainer';
import { ClientContainer } from '@containers/clientContainer/clientContainer';
import { ProjectsContainer } from '@containers/projectsContainer/ProjectsContainer';
import { ProjectsListingContainer } from '@containers/projectsListingContainer/projectsListingContainer';
import { SettingsContainer } from '@containers/settingsContainer/SettingsContainer';
import { PipelineTemplateContainer } from '@containers/pipelineTemplateContainer/PipelineTemplateContainer';
import { ContactsContainer } from '@containers/contactsContainer/ContactsContainer';
import { ContactFormContainer } from '@containers/contactFormContainer/contactFormContainer';
import { ContactsListingContainer } from '@containers/contactsListingContainer/contactListingContainer';
import { CandidatesSearchContainer } from './containers/candidatesSearchContainer/CandidatesSearchContainer';
import { CandidatesContainer } from './containers/candidatesContainer/CandidatesContainer';
import { ProjectPipelineContainer } from './containers/projectPipelineContainer/ProjectPipelineContainer';
import { CandidateTable } from 'components/candidateTable/candidateTable';
import { RecruitersContainer } from './containers/recruitersContainer/RecruitersContainer';
import { RecruitersListingContainer } from './containers/recruitersListingContainer/RecruitersListingContainer';
import { TeamListingContainer } from 'containers/teamListingContainer/TeamListingContainer';
import { AddRecuriterContainer } from './containers/addRecruiterContainer/AddRecuriterContainer';
import { JobContainer } from './containers/jobContainer/jobContainer';
import { JobConfirmationContainer } from './containers/jobConfirmationContainer/jobConfirmationContainer';
export const Router = () => {
  return (
    <>
      <Toaster />

      <Routes>
        <Route path={routesPath.login} element={<LoginContainer />} />

        <Route
          path={routesPath.dashboard}
          element={<PrivateRoute component={DashboardContainer} />}
        />

        <Route path={routesPath.client} element={<PrivateRoute component={ClientContainer} />}>
          <Route
            path={routesPath.clientFormPage}
            element={<PrivateRoute component={ClientCreationContainer} />}
          />
          <Route
            path={routesPath.addProjects(':clientId')}
            element={<PrivateRoute component={ProjectFormContainer} />}
          />
          <Route
            path={routesPath.editClient(':clientID')}
            element={<PrivateRoute component={ClientCreationContainer} />}
          />
          <Route
            path={routesPath.addContacts(':clientId')}
            element={<PrivateRoute component={ContactFormContainer} />}
          />
          <Route
            path={routesPath.updateProject(':clientId', ':projectId')}
            element={<PrivateRoute component={ProjectFormContainer} />}
          />
        </Route>
        <Route path={routesPath.job()} element={<PrivateRoute component={JobContainer} />}>
          <Route path={routesPath.jobsFormPage} element={<JobsFormContainer />} />
          <Route path={routesPath.jobEdit(':jobId')} element={<JobsFormContainer />} />
          <Route path={routesPath.publishJob()} element={<JobConfirmationContainer />} />
          <Route path={routesPath.publishOldJob(':jobId')} element={<JobConfirmationContainer />} />
        </Route>

        <Route path={routesPath.jobs} element={<PrivateRoute component={JobsContainer} />} />

        <Route
          path={routesPath.jobApplicants(':jobId')}
          element={<PrivateRoute component={JobApplicantsContainer} />}
        />
        <Route
          path={routesPath.clientProjects}
          element={<PrivateRoute component={ProjectsContainer} />}
        >
          <Route
            path={routesPath.getClientProjects(':clientId')}
            element={<PrivateRoute component={ProjectsListingContainer} />}
          />
        </Route>
        <Route
          path={routesPath.settings()}
          element={<PrivateRoute component={SettingsContainer} />}
        >
          <Route path={routesPath.pipelineList()} element={<PipelineTemplateContainer />} />
          <Route
            path={routesPath.pipelineEdit(':pipelineId')}
            element={<PipelineTemplateContainer />}
          />
        </Route>
        <Route
          path={routesPath.recruiters}
          element={<PrivateRoute component={RecruitersContainer} />}
        >
          <Route
            path={routesPath.recruitersList()}
            element={<PrivateRoute component={RecruitersListingContainer} />}
          />
          <Route
            path={routesPath.recruitersTeams()}
            element={<PrivateRoute component={TeamListingContainer} />}
          />
          <Route
            path={routesPath.recruitersAdd()}
            element={<PrivateRoute component={AddRecuriterContainer} />}
          />
        </Route>
        <Route
          path={routesPath.clientContacts}
          element={<PrivateRoute component={ContactsContainer} />}
        >
          <Route
            path={routesPath.getClientContacts(':clientId')}
            element={<PrivateRoute component={ContactsListingContainer} />}
          />
        </Route>
        <Route
          path={routesPath.searchCandidates()}
          element={<PrivateRoute component={CandidatesSearchContainer} />}
        />
        <Route
          path={routesPath.project()}
          element={<PrivateRoute component={CandidatesContainer} />}
        >
          <Route
            path={routesPath.projectPipeline(':projectId', ':pipelineId')}
            element={<PrivateRoute component={CandidatesContainer} />}
          >
            <Route
              path={routesPath.projectPipeline(':projectId', ':pipelineId')}
              element={<ProjectPipelineContainer />}
            />
            {/* <Route
              path={routesPath.updateProject(':projectId', ':clientId')}
              element={<PrivateRoute component={ProjectFormContainer} />}
            /> */}
          </Route>
          <Route path={routesPath?.projectCandidates(':projectId')} element={<CandidateTable />} />
        </Route>
      </Routes>
    </>
  );
};
