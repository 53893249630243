import '@asseinfo/react-kanban/dist/styles.css';
import classNames from 'classnames';
import Board from '@asseinfo/react-kanban';

import { KanbanCard } from '@components/kanbanCard/kanbanCard';
import { KanbanColumn } from '@components/kanbanColumn/kanbanColumn';
import styles from 'components/kanbanCard/kanban.module.scss';
import { apiUrls } from 'constants/apiurls';
import { useParams } from 'react-router-dom';
import dataService from '@services/dataService';
import { useEffect, useState } from 'react';
import { Page } from '@components/page/page';
import projects from '../../redux/modules/projects';
import { KanbanActionsBar } from '../../components/kanbanActionBar/kanbanActionBar';
import { cloneDeep, find } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { handleViewsData } from '../../redux/common/commonSlice';
import { Spinner } from 'components/loader/loader';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { MdDelete as DeleteIcon } from 'react-icons/md';
import { ProjectBinPipelineContainer } from '../projectBinPipelineContainer/ProjectBinPipelineContainer';
import _ from 'lodash';
import { RoundButton } from '../../components/roundButton/roundButton';

export const ProjectPipelineContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [board, setBoard] = useState();
  const [selectedCard, setSelectedcard] = useState([]);
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const {
    data: projectData,
    isLoading: isLoadingProject,
    error: errorProject,
    isSuccess: successProject,
  } = projects.list().useProjectlistQuery(
    { params: { id: params.projectId }, extendedPath: apiUrls.getProject() },
    {
      skip: !params.projectId,
    },
  );

  const [showCandidateBin, setShowCandidateBin] = useState(false);

  const [updateProject, updateProjectData] = projects.form().useProjectupdateMutation();

  useEffect(() => {
    if (successProject || selectedCard?.length) {
      let result = dataService.parsedKanbanColumns(
        projectData?.data,
        selectedCard,
        getActionBar,
        getCardCheckBox,
      );

      setBoard(result);
      debugger;
    } else {
      setBoard(null);
    }
  }, [projectData, selectedCard]);

  useEffect(() => {
    if (updateProjectData?.isSuccess) {
      toast.success('Project Pipeline Updated!');
    }
    if (updateProjectData?.error) {
      toast.error('Failed To Update');
    }
  }, [updateProjectData]);

  useEffect(() => {
    if (!projectData?.data?.pipeline_id) {
      setBoard(null);
    }
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  const onCardDragEnd = (card, from, to, type, stages) => {
    let tempSelectedCards = [...selectedCard];
    let cards = card;
    let projectCandidates = [];
    if (type === 'bin') {
      projectCandidates = cloneDeep(projectData?.data)?.projects_pipeline_stages_users;
      cards = tempSelectedCards.filter((cd) => cd.pipeline_stage_id === from.fromColumnId);
      tempSelectedCards = tempSelectedCards.filter(
        (cd) => cd.pipeline_stage_id !== from.fromColumnId,
      );

      projectCandidates.map((candidate, index) => {
        if (find(cards, { id: candidate.id })) {
          projectCandidates[index].binned = 1;
        }
      });
      let tempProject = {};
      tempProject.id = parseInt(params?.projectId);
      tempProject.projects_pipeline_stages_users = projectCandidates || [];

      updateProject(tempProject);

      return;
    }

    if (type === 'kanBnaBar') {
      cards = tempSelectedCards.filter((cd) => cd.pipeline_stage_id === from.fromColumnId);
      tempSelectedCards = tempSelectedCards.filter(
        (cd) => cd.pipeline_stage_id !== from.fromColumnId,
      );
      cards.map((cd) => {
        cd.pipeline_stage_id = to.toColumnId;
        return cd;
      });
    } else {
      cards['pipeline_stage_id'] = to.toColumnId;
    }
    let tempBoard = cloneDeep(board);
    let sourceColumnIndex = tempBoard.columns.findIndex((col) => col.id === from.fromColumnId);
    let targetColumnIndex = tempBoard.columns.findIndex((col) => col.id === to.toColumnId);

    let sourceColumn = tempBoard.columns
      .find((col) => col.id === from.fromColumnId)
      .cards.filter((card, index) => index !== from.fromPosition);
    let targetColumn = tempBoard.columns.find((col) => col.id === to.toColumnId);
    if (from.fromColumnId === to.toColumnId) {
      sourceColumn.splice(to.toPosition, 0, cards);

      tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
      tempBoard.columns.map((col) => (col['candidateCount'] = col.cards?.length));
    } else {
      if (type === 'kanBnaBar') {
        tempBoard.columns[sourceColumnIndex].cards = tempBoard.columns[
          sourceColumnIndex
        ].cards.filter((cd) => !find(cards, { id: cd.id }));
        tempBoard.columns[targetColumnIndex].cards = [...targetColumn.cards, ...cards];
      } else {
        tempBoard.columns[sourceColumnIndex].cards = sourceColumn;
        targetColumn.cards.splice(to.toPosition, 0, cards);
        tempBoard.columns[targetColumnIndex] = targetColumn;
      }
      tempBoard.columns.map((col) => (col['candidateCount'] = col.cards?.length));
    }
    let tempProject = {};
    tempProject.id = tempBoard.id;
    tempProject.projects_pipeline_stages_users = [];

    tempBoard.columns.map((col) => {
      tempProject.projects_pipeline_stages_users = [
        ...tempProject.projects_pipeline_stages_users,
        col.cards,
      ];
    });
    // console.log('temping====>', tempProject);
    updateProject(tempProject);
    // setSelectedcard(tempSelectedCards);

    // setBoard(tempBoard);
  };

  const getActionBar = (currentPosition) => {
    return (
      <KanbanActionsBar
        pipelineStages={board}
        currentPosition={currentPosition}
        handleKanbanMovement={onCardDragEnd}
        handleUserDelete={handleUserDelete}
        visible
      />
    );
  };
  const handleUserDelete = (from) => {
    let tempBoard = cloneDeep(board);
    let tempSelectedCards = [...selectedCard];
    tempSelectedCards = tempSelectedCards.filter(
      (cd) => cd.pipeline_stage_id !== tempBoard.columns[from.fromPosition].id,
    );
    tempBoard.columns[from.fromPosition].cards = tempBoard.columns[from.fromPosition].cards.filter(
      (cd) => !find(selectedCard, { id: cd.id }),
    );

    let tempProject = {};
    tempProject.id = tempBoard.id;
    tempProject.projects_pipeline_stages_users = [];

    tempBoard.columns.map((col) => {
      tempProject.projects_pipeline_stages_users = [
        ...tempProject.projects_pipeline_stages_users,
        ...col.cards,
      ];
    });

    updateProject(tempProject);
    setSelectedcard(tempSelectedCards);
    setBoard(tempBoard);
  };

  const handleSelectedCards = (e, card) => {
    // e.stopPropogation();
    let tempSelectedCard = [...selectedCard];

    let index = tempSelectedCard.findIndex((kanabnCard) => kanabnCard.id === card?.id);

    if (index >= 0) {
      tempSelectedCard.splice(index, 1);
    } else {
      tempSelectedCard.push(card);
    }

    setSelectedcard(tempSelectedCard);
    // setTick(card);
  };

  const getCardCheckBox = (card) => {
    return (
      <div className="d-flex align-items-center">
        <label className={classNames('form-element')}>
          <input type="checkbox" onChange={(e) => handleSelectedCards(e, card)} />
        </label>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(styles.reactKanbanBoardWrapper, 'mt-5 pt-5', {
          [styles.sidebarAdjust]: !isEmpty(currentUIState),
          '': !currentUIState,
        })}
        style={!isEmpty(currentUIState) ? { marginLeft: '320px' } : { marginLeft: '0' }}
      >
        <Page>
          {board && (
            <Board
              renderCard={KanbanCard}
              onCardDragEnd={onCardDragEnd}
              renderColumnHeader={KanbanColumn}
              disableCardDrag={updateProjectData?.isLoading}
              allowColumnDrag={false}
            >
              {board}
            </Board>
          )}
          {!board && !isLoadingProject && (
            <EmptyCard
              isCard
              title="Things are looking a little\nbare here."
              description="You haven’t added any pipeline to this project yet."
            ></EmptyCard>
          )}
          {isLoadingProject && <Spinner />}
        </Page>

        {board &&
          !isLoadingProject &&
          (!showCandidateBin && projectData?.data?.projects_pipeline_stages_users?.length ? (
            <>
              <div className="d-flex justify-content-center">
                <RoundButton
                  // className={'mb-2'}
                  theme={'white'}
                  size={'medium'}
                  onClick={() => setShowCandidateBin(true)}
                >
                  <DeleteIcon color="#B7BEC3" />
                </RoundButton>
              </div>
              <div className="d-flex justify-content-center mt-1" style={{ color: '#B7BEC3' }}>
                <span className="mt-1">Candidate Bin </span>
                {projectData?.data?.projects_pipeline_stages_users?.filter(
                  (user) => user.binned === 1,
                )?.length >= 0 ? (
                  <p className="p-1 px-2 mx-2 d-inline">
                    {
                      projectData?.data?.projects_pipeline_stages_users?.filter(
                        (user) => user.binned === 1,
                      )?.length
                    }
                  </p>
                ) : null}
              </div>
            </>
          ) : (
            projectData?.data?.projects_pipeline_stages_users?.length && (
              <div className="row mt-4" style={{ background: '#FF3434' }}>
                <div className="col-lg-6 ps-5 mt-2 text-light">
                  CANDIDATE BIN{' '}
                  {projectData?.data?.projects_pipeline_stages_users?.filter(
                    (user) => user.binned === 1,
                  )?.length >= 0 && (
                    <p className="p-1 px-2 mx-2 mt-2 d-inline">
                      {
                        projectData?.data?.projects_pipeline_stages_users?.filter(
                          (user) => user.binned === 1,
                        )?.length
                      }
                    </p>
                  )}
                </div>
                <div className="col-lg-6">
                  <div style={{ marginTop: '-43px', padding: '14px', marginLeft: '-50px' }}>
                    <RoundButton
                      style={{ maginBottom: '-50px' }}
                      theme={'red'}
                      size={'medium'}
                      onClick={() => setShowCandidateBin(false)}
                    >
                      <DeleteIcon color="#FFFFFF" />
                    </RoundButton>
                  </div>
                </div>
              </div>
            )
          ))}
      </div>
      {showCandidateBin && <ProjectBinPipelineContainer />}
    </>
  );
};
