import classNames from 'classnames';
import { JobIndicatorPreview } from '../jobIndicatorPreview/JobIndicatorPreview';
import styles from '../jobForm/jobForm.module.scss';
import { Color } from '../color/color';
import { TextInput } from '../form/textInput';
import { Acronym } from '../acronym/acronym';
import { useWatch } from 'react-hook-form';

export const JobFormTitle = (props) => {
  const { title, colors } = props;
  const acronym = useWatch({ name: 'acronym' });
  const color_id = useWatch({ name: 'colour_id' });


  return (
    <div className={classNames(styles.compCardContainer)}>
      {/*<p>*/}
      {/*  {acronymValue}*/}
      {/*</p>*/}
      {/*<p>*/}
      {/*  {colorValue}*/}
      {/*</p>*/}
      <div className={classNames(styles.compCardTitleContainer)}>
        <span className={classNames(styles.dividerTextLarge)}>Job title</span>
      </div>
      <div className={classNames(styles.compCardContent, 'd-block')}>
        <span className={classNames(styles.inputTitle)}>
          Job post title*
          <div className={classNames(styles.textSmall)}>
            {"The title of the position that you're advertising"}
          </div>
        </span>
        <div className={classNames(styles.fieldWrapper)}>
          <TextInput placeholder="Enter Job Title" required={true} name="title" type="text" />
        </div>
        <div className={classNames(styles.fieldWrapper, styles.jobIndicatorFormWrapper, 'row')}>
          <div className={classNames(styles.avatar_dot, 'col-lg-2 col-4')}>
            <JobIndicatorPreview
              className="jobHeaderIndicator"
              colors={colors}
              color_id={color_id}
              acronym={acronym}
            />
          </div>
          <div className={classNames(styles.jobIndicatorFieldWrapper, 'col-lg-10 col-8 ps-0')}>
            <span className={classNames(styles.inputTitle)}>
              Job indicator
              <div className={classNames(styles.textSmall)}>
                Assign a colour and acronym for this job to help identify it. This will only be seen
                by you.
              </div>
            </span>
          </div>
          <div className={classNames(styles.mtMinus40, 'col-sm-10 col-12')}>
            <div className={classNames(styles.acronymComp, styles.textSmall)}>
              <Acronym name="indicator_acronym" color_id={color_id} acronym={acronym} />
              <div className={classNames(styles.colorFieldWrapper)}>
                <Color name="colour_id" colors={colors} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
